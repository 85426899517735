<script setup></script>

<template>
  <div class="chat-empty-view">
    <div class="chat-empty-info">
      <div class="chat-empty-info-head">
        <img
          src="https://cdn.itemsatis.com/uploads/admin/w1fYFDM4xehCmqy9S5VuOIvHg.png"
        />
      </div>
      <div class="chat-empty-info-block">
        <div class="chat-empty-info-title">
          <h3 class="is-heading mt-3">Önemli Bilgilendirme</h3>
        </div>
        <div class="chat-empty-info-message">
          <p class="no-margin is-description">
            Mesajlaşma sistemi üzerinden size ulaşıp ilanınızı satın aldım diyen
            kişilere itibar etmeyiniz ve canlı desteğe raporlayınız.
            <span class="text-primary-light">
              İtemSatış sizinle hiçbir şekilde Chat, Facebook, Instagram veya
              WhatsApp üzerinden iletişime geçmez!</span
            >
          </p>
        </div>
      </div>
      <div class="chat-empty-info-block">
        <div class="chat-empty-info-title">
          <h3 class="is-heading mt-3">
            İlanınızın satıldığını nasıl anlayabilirsiniz?
          </h3>
        </div>
        <div class="chat-empty-info-message mt-3">
          <p class="no-margin is-description">
            Size ITEM SATIS başlığıyla SMS, no-reply@itemsatis.com adresinden
            mail ve site içerisindeki bildirimlerim sayfanızdan ulaşabileceğiniz
            teslimat mesajı gönderiyoruz. Bunun haricinde bir yerden gelen
            mesajı dikkate almayınız.
          </p>
        </div>
      </div>
      <div class="chat-empty-info-footer">
        <p class="no-margin is-description">
          <span class="text-primary-light">
            LÜTFEN sizinle ilanı satın aldım diyerek iletişime geçen kişilere
            prim vermeyiniz ve destek ekibine bildiriniz!</span
          >
          Sizlerin de desteğiyle daima güvenli bir alışveriş platformu olmaya
          devam edeceğiz.
        </p>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.chat-empty-view {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .chat-empty-info {
    max-width: 500px;
    margin-inline: auto;
    text-align: center;
    padding: 2rem;
    border-radius: 5px;
    .chat-empty-info-head {
      img {
        width: 8rem;
        height: 8rem;
      }
    }
  }
}
</style>
